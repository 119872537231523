import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../Context/AuthContext';

const Organization = () => {
  const navigate = useNavigate();
  const { decodeS1 } = useAuth();
  const s1 = decodeS1();
  const [select, setSelect] = useState(0);

  const moveOrganizationPage = (category) => {
    navigate(`/organization/${category}`, { state: { category: s1 } });
  };

  const handleSelect = (num) => {
    if (select === num) {
      setSelect(0);
    } else {
      setSelect(num);
    }
  };
  const moveBoard = (cate) => {
    navigate(`/board/${cate}`, { state: { cate: cate } });
  };
  const movePage = (path) => {
    navigate(path);
  };
  return (
    <div className="sub_wrap">
      <div className="sub_back">
        <div className="sub_banner_area">
          <div className="sub_banner_back">
            <div className="navi_text">
              <div className="home_icon_sub"></div>
              {'> '}융합원 소개{' > '}조직도
            </div>
            <div className="banner_main_text">조직도</div>
          </div>
        </div>
        <div className="navi_area">
          <div className="navi_back">
            <div className="navi_box" onClick={() => handleSelect(1)}>
              <div className="navi_main_text">융합원소개</div>
              <div className="navi_arrow"></div>
              <div className={`navi_select_box ${select === 1 && 'active'}`}>
                <div className="select_row" onClick={() => moveBoard('notice')}>
                  알림 및 소식
                </div>
                <div className="select_row" onClick={() => movePage('/fusionSupportProgram')}>
                  사업분야
                </div>
                <div className="select_row" onClick={() => movePage('/inquiryinfo')}>
                  기업연구동
                </div>
                <div className="select_row" onClick={() => movePage('/meetingroom')}>
                  보유시설
                </div>
                <div className="select_row" onClick={() => movePage('/intro')}>
                  융합원소개
                </div>
              </div>
            </div>
            <div className="navi_box" onClick={() => handleSelect(2)}>
              <div className="navi_main_text">조직도</div>
              <div className="navi_arrow"></div>
              <div className={`navi_select_box ${select === 2 && 'active'}`}>
                {/* <div className="select_row" onClick={() => movePage("/intro")}>
                  인사말
                </div> */}
                <div className="select_row" onClick={() => movePage('/vision')}>
                  비전 및 목표
                </div>
                <div className="select_row" onClick={() => movePage('/history')}>
                  연혁
                </div>
                <div className="select_row" onClick={() => movePage('/organization')}>
                  조직도
                </div>
                <div className="select_row" onClick={() => movePage('/introduce')}>
                  소개자료
                </div>
                <div className="select_row" onClick={() => movePage('/map')}>
                  오시는 길
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sub_main_area founded">
          <div className="sub_main_back">
            <div className="vision_container">
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">조직도</div>
              </div>
              <div className="founded_img_box formation_one">
                <div className="organization_img formation_one"></div>
              </div>
              <div className="organization-wrap">
                <div className="organization-btn" onClick={() => moveOrganizationPage('management')}>
                  직원 정보 {'>'}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Organization;
